/* eslint-disable react/no-unused-state  */
/* eslint-disable func-names */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArcText from 'arc-text';
import { getWindowDimensions } from '@utils';

const ArcStyle = styled.div``;

export default class ReactArcText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSee: false,
      arc: props.arc,
    };
  }

  componentDidMount() {
    const { shouldRender } = this.props;
    const x = getWindowDimensions().width;
    const { arc } = this.state;
    const a = 0.1571;
    const b = 94.5238;
    const dynamicArc = a * x + b;
    const newArc = dynamicArc > arc ? arc : dynamicArc;
    this.setState({ arc: newArc });
    if (shouldRender) this.arcLetters();
  }

  arcLetters() {
    const { text, direction } = this.props;
    const { arc } = this.state;
    if (this.container) {
      if (this.textCyrcle) {
        this.textCyrcle.destroy();
      }

      this.container.innerHTML = text;
      this.textCyrcle = new ArcText(this.container);
      this.textCyrcle.arc(arc);
      this.textCyrcle.direction(direction);
      setTimeout(() => {
        if (this.textCyrcle) {
          this.textCyrcle.destroy();
        }
        this.container.innerHTML = text;
        this.textCyrcle = new ArcText(this.container);
        this.textCyrcle.arc(arc);
        this.textCyrcle.direction(direction);
      }, 100);
    }
  }

  render() {
    const { shouldRender } = this.props;
    const { classNames } = this.props;
    if (shouldRender) {
      this.arcLetters();
      return (
        <ArcStyle
          className={`react-arc-text ${classNames}`}
          ref={(el) => {
            this.container = el;
          }}
        />
      );
    }
    return <div className="arc-placeholder" />;
  }
}

ReactArcText.defaultProps = {
  text: '',
  classNames: '',
  direction: 1,
  arc: 1,
  shouldRender: true,
};

ReactArcText.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
  direction: PropTypes.number,
  arc: PropTypes.number,
  shouldRender: PropTypes.bool,
};
