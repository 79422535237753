import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const Fade = ({ in: inProp, children, shouldFade, duration, animations }) => {
  const defaultStyle = {
    transition: shouldFade ? `opacity ${duration}ms ease-in` : '',
    opacity: 1,
  };

  const transitionStyles = {
    entering: {
      animation: animations.entering
        ? `${duration}ms ${animations.entering}`
        : '',
    },
    entered: {
      animation: animations.entered
        ? `${duration}ms ${animations.entered}`
        : '',
    },
    exiting: { opacity: 1 },
    exited: { opacity: 1 },
  };
  return (
    <Transition
      in={inProp}
      timeout={{
        enter: duration,
      }}
    >
      {(state) => {
        return (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
};

Fade.defaultProps = {
  shouldFade: false,
  duration: 1000,
  animations: {},
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool.isRequired,
  shouldFade: PropTypes.bool,
  duration: PropTypes.number,
  animations: PropTypes.object,
};

export default Fade;
