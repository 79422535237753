import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { AnimatedArcText } from '@components';
import { theme, media, mixins } from '@styles';

const { colors, fontSizes, fonts } = theme;

const ArcStyle = styled.div`
  position: relative;
  z-index: 10;
  color: ${colors.orange};
  font-size: ${fontSizes.jumbo};
  ${media.phablet`font-size: ${fontSizes.h1};`};
  ${media.thone`font-size: ${fontSizes.huge};`};
  ${media.phone`font-size: ${fontSizes.jumbo};`};
  font-family: ${fonts.CooperBlack};
  font-weight: normal;
  max-width: 250px;
  min-width: 250px;
  padding-left: -20px;
`;

const BlurbImage = styled.div`
  float: right;
  position: relative;
  ${media.tablet`padding-left: 0px;`};
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledAvatar = styled.div`
  margin-top: -120px;
  ${media.tablet`margin-top: -130px;`};
  ${media.thone`margin-top: -110px;`};
  ${media.phablet`margin-top: -100px;`};
  max-width: 250px;
  min-width: 250px;
  a {
    &:focus {
      outline: 0;
    }
  }
`;

const StyledAvatarLink = styled.a`
  ${mixins.styledAvatarLink};
`;

const BlurbText = styled.div`
  margin-top: -100px;
  position: relative;
  & em,
  a {
    color: ${colors.black};
    font-style: normal;
  }
  & a:link {
    color: ${colors.black};
    text-decoration: underline;
  }
  & a:visited {
    color: ${colors.black};
    text-decoration: underline;
  }
  & a:hover {
    color: ${colors.lightBlue};
    text-decoration: underline;
  }
  & a:active {
    text-decoration: underline;
  }
`;

const BlurbContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  max-width: 80%;
  ${media.tiny`flex-direction: column;`};
  ${media.phone`flex-direction: column;`};
  ${media.phablet`flex-direction: column;`};
  padding-top: 50px;
`;
const StyledBody = styled.div`
  margin-top: 100px;
  float: left;
  font-family: ${fonts.SourceCodePro};
  font-size: ${fontSizes.md};
  ${media.thone`font-size: ${fontSizes.sm};`};
`;

const StyledImage = styled(Img)`
  position: relative;
`;

const UnstyledLink = styled.a`
  color: inherit;
`;

export default class Blurb extends Component {
  constructor(props) {
    super(props);
    const { frontmatter } = props.data[0].node;
    const { titleArc } = frontmatter;
    this.state = {
      arc: parseInt(titleArc, 10),
    };
  }

  render() {
    const { data } = this.props;
    const { frontmatter, html } = data[0].node;
    const { title, name, avatar, resume } = frontmatter;
    const { arc } = this.state;
    return (
      <BlurbContainer className="hero-left">
        <StyledBody>
          <BlurbText dangerouslySetInnerHTML={{ __html: html }} />
        </StyledBody>
        <BlurbImage>
          <ArcStyle>
            <UnstyledLink href={resume} target="_blank">
              <AnimatedArcText text={title} arc={arc} shouldAnimate={false} />
            </UnstyledLink>
          </ArcStyle>
          <StyledAvatar>
            <StyledAvatarLink href={resume} target="_blank">
              <StyledImage fluid={avatar.childImageSharp.fluid} alt={name} />
            </StyledAvatarLink>
          </StyledAvatar>
        </BlurbImage>
      </BlurbContainer>
    );
  }
}

Blurb.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
