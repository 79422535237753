import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Head } from '@components';
// import Header from './header';
import styled from 'styled-components';
import { Main } from '@styles';

const StyledMainContainer = styled(Main)``;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="root">
      <StyledMainContainer>
        <Head metadata={data.site.siteMetadata} />
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div>
          <main>{children}</main>
          <footer>© {new Date().getFullYear()}, Built with love ❤️</footer>
        </div>
      </StyledMainContainer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
