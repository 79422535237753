import styled from 'styled-components';
import mixins from './mixins';
import { media } from './media';
import theme from './theme';

const Main = styled.main`
  font-family: ${theme.fonts.SourceCodePro};
  ${mixins.sidePadding};
  max-width: 1600px;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  ${media.giant`
    padding-top: 100px;
    padding-bottom: 100px;
  `};
  ${media.bigDesktop`
    padding-top: 100px;
    padding-bottom: 100px;
  `};
  ${media.desktop`
    padding-top: 75px;
    padding-bottom: 75px;
  `};
  ${media.tablet`
    padding-top: 75px;
    padding-bottom: 75px;
  `};
  ${media.phablet`
    padding-top: 125px;
    padding-bottom: 125px;
  `};

  &.fillHeight {
    padding-top: 0;
    padding-bottom: 0;
    ${media.desktop`
    padding-top: 0;
    padding-bottom: 0;
  `};
    ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `};
    ${media.phablet`
    padding-top: 0;
    padding-bottom: 0;
  `};
  }
`;

export default Main;
