const theme = {
  fonts: {
    CooperBlack: 'cooper-black-std, serif',
    SourceCodePro: 'source-code-pro, monospace',
    RocGrotesk: 'roc-grotesk, sans-serif',
    RocGroteskWide: 'roc-grotesk-wide, sans-serif',
  },
  fontSizes: {
    xs: '12px',
    smish: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '22px',
    h3: '32px',
    h1: '42px',
    huge: '50px',
    jumbo: '60px',
    jumbox: '64px',
    jumboxx: '68px',
  },
  colors: {
    orange: '#FFB342',
    lightBlue: '#9EB9FF',
    darkNavy: '#020c1b',
    white: '#FFFFFF',
    black: '#000000',
  },
  borderRadius: '10px',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
  animations: {
    bounceIn: 'bounceIn',
    swing: 'swing',
  },
};

export default theme;
