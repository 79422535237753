import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from '@config';
import favicon from '@images/favicons/favicon.ico';
// import ogImage from '@images/og.png';
import appleIcon57x57 from '@images/favicons/apple-touch-icon-57x57.png';
import appleIcon60x60 from '@images/favicons/apple-touch-icon-60x60.png';
import appleIcon72x72 from '@images/favicons/apple-touch-icon-72x72.png';
import appleIcon76x76 from '@images/favicons/apple-touch-icon-76x76.png';
import appleIcon114x114 from '@images/favicons/apple-touch-icon-114x114.png';
import appleIcon120x120 from '@images/favicons/apple-touch-icon-120x120.png';
import appleIcon144x144 from '@images/favicons/apple-touch-icon-144x144.png';
import appleIcon152x152 from '@images/favicons/apple-touch-icon-152x152.png';
import appleIcon180x180 from '@images/favicons/apple-touch-icon-180x180.png';
import androidIcon36x36 from '@images/favicons/android-chrome-36x36.png';
import androidIcon48x48 from '@images/favicons/android-chrome-48x48.png';
import androidIcon72x72 from '@images/favicons/android-chrome-72x72.png';
import androidIcon96x96 from '@images/favicons/android-chrome-96x96.png';
import androidIcon144x144 from '@images/favicons/android-chrome-144x144.png';
import androidIcon192x192 from '@images/favicons/android-chrome-192x192.png';
import androidIcon256x256 from '@images/favicons/android-chrome-256x256.png';
import androidIcon384x384 from '@images/favicons/android-chrome-384x384.png';
import androidIcon512x512 from '@images/favicons/android-chrome-512x512.png';
import favicon16x16 from '@images/favicons/favicon-16x16.png';
import favicon32x32 from '@images/favicons/favicon-32x32.png';
import msIcon150x150 from '@images/favicons/mstile-150x150.png';
import safariPinSvg from '@images/favicons/safari-pinned-tab.svg';

import '@styles/animate.min.css';

const Head = ({ metadata }) => (
  <Helmet>
    <html lang="en" prefix="og: http://ogp.me/ns#" />
    <title itemProp="name" lang="en">
      {metadata.title}
    </title>
    <link rel="shortcut icon" href={favicon} />
    <link rel="canonical" href="https://alexhaynes.org" />

    <link rel="stylesheet" href="https://use.typekit.net/evm0wxz.css" />

    <meta name="description" content={metadata.description} />
    <meta name="keywords" content={config.siteKeywords} />
    <meta name="google-site-verification" content={config.googleVerification} />
    <meta property="og:title" content={metadata.title} />
    <meta property="og:description" content={metadata.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={metadata.siteUrl} />
    <meta property="og:site_name" content={metadata.title} />
    {/* <meta property="og:image" content={`${config.siteUrl}${ogImage}`} /> */}
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:locale" content={config.siteLanguage} />
    <meta itemProp="name" content={metadata.title} />
    <meta itemProp="description" content={metadata.description} />
    {/* <meta itemProp="image" content={`${config.siteUrl}${ogImage}`} /> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={metadata.siteUrl} />
    <meta name="twitter:site" content={config.twitterHandle} />
    <meta name="twitter:creator" content={config.twitterHandle} />
    <meta name="twitter:title" content={metadata.title} />
    <meta name="twitter:description" content={metadata.description} />
    {/* <metaname="twitter:image"content={`${config.siteUrl}${ogImage}`} /> */}
    <meta name="twitter:image:alt" content={metadata.title} />

    <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57x57} />
    <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60x60} />
    <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72x72} />
    <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76x76} />
    <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114x114} />
    <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120x120} />
    <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144x144} />
    <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152x152} />
    <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180x180} />
    <link rel="icon" type="image/png" sizes="36x36" href={androidIcon36x36} />
    <link rel="icon" type="image/png" sizes="48x48" href={androidIcon48x48} />
    <link rel="icon" type="image/png" sizes="72x72" href={androidIcon72x72} />
    <link rel="icon" type="image/png" sizes="96x96" href={androidIcon96x96} />
    <link
      rel="icon"
      type="image/png"
      sizes="144x144"
      href={androidIcon144x144}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={androidIcon192x192}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="256x256"
      href={androidIcon256x256}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="384x384"
      href={androidIcon384x384}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="512x512"
      href={androidIcon512x512}
    />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
    <link rel="mask-icon" href={safariPinSvg} color="red" />
    <link rel="icon" type="image/png" href={appleIcon180x180} />
    <meta name="msapplication-TileImage" content={msIcon150x150} />
    <meta name="theme-color" content={config.colors.orange} />
  </Helmet>
);

export default Head;

Head.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.string).isRequired,
};
