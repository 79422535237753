import { css } from 'styled-components';
import { media } from './media';
import theme from './theme';

const { colors } = theme;

const inlineLink = css`
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: ${theme.transition};
  cursor: pointer;
  color: ${theme.colors.lightBlue};
  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.lightBlue};
    outline: 0;
    &:after {
      width: 100%;
    }
    & > * {
      color: ${theme.colors.lightBlue} !important;
      transition: ${theme.transition};
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    position: relative;
    bottom: 0.37em;
    background-color: ${theme.colors.lightBlue};
    transition: ${theme.transition};
    opacity: 0.5;
  }
`;

const mixins = {
  sidePadding: css`
    padding: 0 80px;
    ${media.giant`padding: 0 80px;`};
    ${media.bigDesktop`padding: 0 80px;`};
    ${media.desktop`padding: 0 60px;`};
    ${media.tablet`padding: 0 40px;`};
    ${media.phablet`padding: 0 30px;`};
  `,
  inlineLink,
  styledAvatarLink: css`
    width: 100%;
    ${inlineLink};
    position: relative;
    &:hover,
    &:focus {
      background: transparent;
      &:after {
        top: 10px;
        left: 10px;
      }
    }
    &:after {
      background: transparent;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: ${theme.transition};
    }
    &:after {
      border: 4px solid ${colors.lightBLue};
      top: 15px;
      left: 15px;
      z-index: -1;
    }
  `,
};

export default mixins;
