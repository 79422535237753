import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Hero, Blurb } from '@components';
import { media } from '@styles';

const TopContainer = styled.div`
  display: flex;
  ${media.tiny`flex-direction: column;`};
  ${media.phone`flex-direction: column;`};
  ${media.phablet`flex-direction: column;`};
  ${media.thone`flex-direction: column;`};
  ${media.tablet`flex-direction: column;`};
  ${media.desktop`flex-direction: column;`};
  ${media.bigDesktop`flex-direction: column;`};
`;

const Spacer = styled.div`
  height: 0px;
  min-width: 100px;
`;

const Top = (props) => {
  const { hero, blurb } = props;
  return (
    <TopContainer>
      <Hero data={hero} />
      <Spacer />
      <Blurb data={blurb} />
    </TopContainer>
  );
};

Top.propTypes = {
  hero: PropTypes.arrayOf(PropTypes.object).isRequired,
  blurb: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Top;
