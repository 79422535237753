import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { github } from '@config';
import { AnimatedArcText } from '@components';
import { theme, media, mixins } from '@styles';

const { colors, fontSizes, fonts } = theme;

const ArcStyle = styled.div`
  position: relative;
  z-index: 10;
  color: ${colors.orange};
  font-size: ${fontSizes.jumbo};
  ${media.giant`font-size: ${fontSizes.h1};`};
  ${media.bigDesktop`font-size: ${fontSizes.jumbo};`};
  ${media.thone`font-size: ${fontSizes.huge};`};
  ${media.phablet`font-size: ${fontSizes.h1};`};
  font-family: ${fonts.CooperBlack};
  margin: 20px;
  font-weight: normal;
`;

const StyledAvatar = styled.div`
  margin-top: -140px;
  ${media.giant`margin-top: -80px;`}
  ${media.bigDesktop`margin-top: -120px;`}
  ${media.tablet`margin-top: -130px;`};
  ${media.thone`margin-top: -110px;`};
  ${media.phablet`margin-top: -100px;`};
  position: relative;
  a {
    &:focus {
      outline: 0;
    }
  }
`;

const StyledAvatarLink = styled.a`
  ${mixins.styledAvatarLink};
`;

const HeroContainer = styled.div`
  position: relative;
  max-width: 400px;
  ${media.giant`max-width:300px;`};
  ${media.bigDesktop`max-width:400px;`};
  width: 80%;
  margin: 0 auto;
`;
const StyledHeadline = styled.div`
  font-family: ${fonts.RocGrotesk};
  font-weight: 500;
  ${media.phablet`font-size: ${fontSizes.md};`};
  ${media.giant`font-size: ${fontSizes.lg};`};
  font-size: ${fontSizes.xxl};
  line-height: 1.2;
  margin-top: 1.2em;
  & em,
  a {
    color: ${colors.lightBlue};
    font-style: normal;
  }
  & a:link {
    text-decoration: none;
  }
  & a:visited {
    text-decoration: none;
  }
  & a:hover {
    text-decoration: underline;
  }
  & a:active {
    text-decoration: underline;
  }
`;

const StyledImage = styled(Img)`
  position: relative;
`;

export default class Hero extends Component {
  constructor(props) {
    super(props);
    const { frontmatter } = props.data[0].node;
    const { titleArc } = frontmatter;
    this.state = {
      arc: parseInt(titleArc, 10),
    };
  }

  render() {
    const { data } = this.props;
    const { frontmatter, html } = data[0].node;
    const { title, name, avatar } = frontmatter;
    const { arc } = this.state;
    return (
      <HeroContainer className="hero-left">
        <ArcStyle>
          <AnimatedArcText text={title} arc={arc} delay={100} />
        </ArcStyle>
        <StyledAvatar>
          <StyledAvatarLink href={github}>
            <StyledImage fluid={avatar.childImageSharp.fluid} alt={name} />
          </StyledAvatarLink>
        </StyledAvatar>
        <StyledHeadline dangerouslySetInnerHTML={{ __html: html }} />
      </HeroContainer>
    );
  }
}

Hero.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
