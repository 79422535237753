module.exports = {
  siteTitle: 'Alex Haynes | Software Engineer',
  siteDescription:
    'Alex Haynes is a software engineer in New York, NY thats interested in distributed systems and UX.',
  siteKeywords:
    'Alex Haynes, Alex, Haynes, alexh, alexhaynes, software engineer, full stack engineer, fullstack engineer, UPenn, Penn, New York, tech, javascript',
  siteUrl: 'https://alexhaynes.org',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'XXXXX',
  googleVerification: 'XXXXXX',
  name: 'Alex Haynes',
  author: 'Alex Haynes',
  location: 'New York, NY',
  email: 'alex@alexhaynes.org',
  github: 'https://github.com/alexh',
  twitterHandle: '@alexhaynes32',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/alexh',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/alexhaynes32',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/alexh.camera',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/alexhaynes32',
    },
  ],
  colors: {
    orange: '#FFB342',
    lightBlue: '#9EB9FF',
    darkNavy: '#020c1b',
    white: '#FFFFFF',
  },
};
