/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-static-element-interactions  */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from '@components/fade';
import ReactArcText from '@components/arc';
import styled from 'styled-components';
import { theme } from '@styles';

const StyledAnimatedArc = styled.div`
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const duration = 1000;

export default class AnimatedArcText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderArc: false,
      startAnimation: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { delay } = this.props;
    setTimeout(() => {
      this.setState({ renderArc: true });
      setTimeout(() => {
        this.setState({ startAnimation: true });
        setTimeout(() => {
          this.setState({ startAnimation: false });
        }, duration * 2);
      }, 100);
    }, delay);
  }

  handleClick() {
    this.setState({ startAnimation: true });
    setTimeout(() => {
      this.setState({ startAnimation: false });
    }, duration * 2);
  }

  render() {
    const { text, direction, arc, classNames, shouldAnimate } = this.props;
    const { renderArc, startAnimation } = this.state;
    const arcComponent = (
      <ReactArcText
        shouldRender={renderArc}
        text={text}
        direction={direction}
        arc={arc}
        className={classNames}
      />
    );
    return shouldAnimate ? (
      <StyledAnimatedArc onClick={this.handleClick}>
        <Fade
          in={startAnimation}
          duration={duration}
          shouldFade
          animations={{
            entering: theme.animations.bounceIn,
            entered: theme.animations.swing,
          }}
        >
          {arcComponent}
        </Fade>
      </StyledAnimatedArc>
    ) : (
      arcComponent
    );
  }
}

AnimatedArcText.defaultProps = {
  text: '',
  classNames: '',
  direction: 1,
  arc: 100,
  delay: 100,
  shouldAnimate: true,
};

AnimatedArcText.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
  direction: PropTypes.number,
  arc: PropTypes.number,
  delay: PropTypes.number,
  shouldAnimate: PropTypes.bool,
};
